<template>
  <div>
    <data-sync-state v-model="dataSynchronized" data='générales' @refresh="refresh"></data-sync-state>

      <b-row>
        <b-col md=5>
            <form-field caption="Type">
              <b-form-input readonly v-model="typeName"/>
            </form-field>
        </b-col>
        <b-col md=5>
          <form-field caption="Echelon">
            <b-form-input readonly v-model="scopeLabel" />
          </form-field>
        </b-col>
        <b-col md=2>
          <form-field caption="Année/saison">
            <b-form-input readonly v-model="year" />
          </form-field>
        </b-col>
      </b-row>
        <form-field v-if="scope == 'region'">
          <template #caption>
            <b-alert variant="secondary" :show="scope == 'region'">
              <i>Comptant pour le Chapionnat de France {{ currentCompetition.SEASON.yearSelectiveCdF }}</i>
            </b-alert>
          </template>
        </form-field>
            <form-field caption="Emplacement">
              <b-form-input type="text" :value="edtLocation" class="location" @change="location_change" :disabled="disabled" :readonly="!canChangeLocation" />
            </form-field>
            <form-field caption="Nom de la compétition" :required="canChangeName" >
              <b-form-input type="text" :value="edtName" @change="name_change" :disabled="disabled" :readonly="!canChangeName" :required="canChangeName"/>
            </form-field>

            <b-row>
              <b-col sm="5">
                <form-field
                  caption='Prévue du'
                  :required="true"
                >
                  <b-form-datepicker :disabled="true || disabled || !canChangeStartDate" v-model="edtStartDate" :min="minStartDate" placeholder="choisissez..." class="startDate" locale='fr' required debounce="750" />
                </form-field>
              </b-col>
              <b-col sm="5">
                <form-field
                  caption="Au"
                  :required="false"
                >
                  <b-form-datepicker v-model="edtEndDate" :min="minEndDate" placeholder="choisissez..." class="endDate" locale="fr" :disabled="true || disabled || !canChangeEndDate || edtStartDate==null || edtStartDate==''" required debounce="750" />
                </form-field>
              </b-col>
              <b-col sm="2">
                <form-field
                  caption="Durée"
                >
                  <b-form-input type="text" :value="$tc('pages.competition-admin.config.duration-in-days', duration)" class="duration" disabled />
                </form-field>
              </b-col>
            </b-row>

            <flex-panel justifyContent="space-between" justify-items="stretch" wrap>
              <div style="flex-grow:1;">
                <slot name="levels"></slot>
              </div>
              <form-field caption="Modalité d'inscription">
                <b-form-select :disabled="disabled || !canChangeRegistrationMode" :value="edtRegistrationMode" :options="lstRegistrationModeOptions" @change="registrationMode_change">
                </b-form-select>
              </form-field>
              <div>
                <slot name="registration-commands"></slot>
              </div>            
            </flex-panel>


      <fieldset class="fieldset">
        <legend>Options</legend>
      <b-row>
        <b-col>
            <checkbox-form-field
              caption='Est "open"'
              :value="edtIsOpen"
              @change="isOpen_change"
              switch
              :disabled="disabled || !canChangeOpenMode"
            >
              <template #info-helper-placeholder>
                <info-helper>
                  Lorsque cette case est cochée, la compétition est ouverte à des compétiteurs "non-fédéraux", potentiellement étrangers, ou dont la licence n'est pas à jour.<br/>
                  Le cas échéant, les résultats de ces compétiteurs pour cette compétition ne seront pas pris en compte dans un quelconque classement officiel FFVL.
                </info-helper>
              </template>
            </checkbox-form-field>
        </b-col>

        <b-col v-if="userHasRole(Roles.Tester)">
            <checkbox-form-field
              disabled
               caption="Est pour simulation uniquement"
               v-model="isForSimulationPurposeOnly"
               v-if="userHasRole(Roles.Tester)"
               switch
            >
            </checkbox-form-field>
        </b-col>
      </b-row>
      </fieldset>

      <fieldset class="fieldset" v-if="!disabled">
        <legend>Propriétaire & Administrateur(s) 
        <info-helper>
          Le propriétaire et les éventuels administrateurs désignés sont les seuls utilisateurs habilités à appliquer la grande majorité des modifications à la compétition pour laquelle ils sont définis.<br/>
          Pour éviter toute erreur de saisie concomittante, il est conseillé de ne désigner qu'un ou deux administrateurs (en plus du propriétaires).<br/>
          Ces personnes ont la responsabilité de configurer "globalement" la compétition.<br/>
          Certaines modifications, propres à l'organisation et la réalisation même de la compétition resterons ensuite accessibles au(x) chef(s) juge de compétition, de manche ou même d'épreuve, voir à l'ensemble du staff.<br/>
          
        </info-helper>
        </legend>
        <competition-admins :competition_id="competition_id" :disabled="disabled">
        </competition-admins>
      </fieldset>
  </div>
</template>

<script>
import DataSyncState from '@/components/Utilities/DataSyncState.vue';
import FormField from '@/components/Panels/FormField.vue';
import CheckboxFormField from '@/components/Panels/CheckboxFormField.vue';

import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
//import { RST_COMPETITION/*RST_ROUND,*/ /* RST_ROUND_COMPULSORY, RST_EVENT, *//* RST_COMPETITOR_NOTES, RST_COMPETITOR_COMPULSORY_NOTE,*//* RST_CATEGORY, */} from "@/LocalDB";
import InfoHelper from '@/components/InfoHelper.vue';
import CompetitionAdmins from "../CompetitionAdmins.vue"
import FlexPanel from '../../../../components/Panels/FlexPanel.vue';
export default{
  model:{
    prop: 'competition_id',
  },
  props:{
    competition_id: { type:String, required:true },
    canChangeName: { type:Boolean, default: true },
    canChangeLocation: { type:Boolean, default: true},
    canChangeStartDate: { type:Boolean, default: true },
    canChangeEndDate: { type: Boolean, default: true }, 
    canChangeRegistrationMode: { type: Boolean, default: true},
    canChangeOpenMode: { type: Boolean, default: true},
    disabled: { type:Boolean, default: false},
  },
  components: { DataSyncState, FormField, CheckboxFormField, InfoHelper, CompetitionAdmins, FlexPanel },
  data(){
    return {
      VERBOSE_MODE: true,

      dataSynchronized: null,
      //d_currentCompetition: null,

      lstRegistrationModeOptions: [
        { value: 'N', text: this.$t('meta.competition-registration-modes.N.select')},
        { value: 'A', text: this.$t('meta.competition-registration-modes.A.select')},
        { value: 'R', text: this.$t('meta.competition-registration-modes.R.select')},
        { value: 'C', text: this.$t('meta.competition-registration-modes.C.select')},
        { value: 'S', text: this.$t('meta.competition-registration-modes.S.select')},
      ],

      d_canChangeName: this.canChangeName,
      d_canChangeLocation: this.canChangeLocation,
      d_canChangeStartDate: this.canChangeStartDate,
   };
  },
  computed:{
    typeName(){
      var ret = this.currentCompetition && this.currentCompetition.COMPETITION_TYPE ? this.currentCompetition.COMPETITION_TYPE.name : '';
      return ret;
    },
    scope(){
      var ret = this.currentCompetition ? this.currentCompetition.scope : '';
      return ret;
    },
    scopeLabel(){
      var ret = this.currentCompetition && this.currentCompetition.SCOPE ? this.currentCompetition.SCOPE.name : '';
      if(this.currentCompetition)
      {
        if(this.currentCompetition.scope == 'region')
          ret += ' - Manche(s) sélective';
        if(this.currentCompetition.scope == 'nation')
          ret += ' - Finale';
      }
      return ret;
    },
    year(){
      return this.currentCompetition ? this.currentCompetition.year : '';
    },
    isForSimulationPurposeOnly(){
      return this.currentCompetition ? this.currentCompetition.isForSimulationPurposeOnly : false;
    },
    edtName(){
      return this.currentCompetition ? this.currentCompetition.name : '';
    },
    edtLocation(){ 
      return this.currentCompetition ? this.currentCompetition.location : '';
    },
    edtIsOpen(){
      return this.currentCompetition ? this.currentCompetition.isOpen : false;
    },
    edtStartDate:{
      get (){
        return this.currentCompetition ? this.currentCompetition.predicted_start_date : null;
      },
    },
    edtEndDate(){
      return this.currentCompetition ? this.currentCompetition.predicted_end_date : null;
    },
    edtRegistrationMode(){
      return this.currentCompetition ? this.currentCompetition.registrationMode : 'N';
    },
    minStartDate(){
      /*var dates = [];
      dates.push(new Date());
      if(this.currentCompetition && this.currentCompetition != null)
        dates.push(new Date(this.currentCompetition.predicted_start_date));
      var ret = new Date(Math.min.apply(null, dates));
      console.log('minStartDate', ret);
      return ret;*/
      return this.currentCompetition && this.currentCompetition != null && this.currentCompetition.predicted_start_date != null 
        ? new Date(Math.min(new Date(), this.currentCompetition.predicted_start_date))
        : new Date();
    },
    minEndDate(){
      if(this.currentCompetition && this.currentCompetition.predicted_start_date != null) 
        return this.currentCompetition.predicted_start_date;
      return this.minStartDate;
    },
    duration(){
      if(!this.currentCompetition) return 'N/A';
      if(this.currentCompetition.predicted_start_date == null) return 'N/A';
      if(this.currentCompetition.predicted_end_date == null) return 1;
      return ((new Date(this.currentCompetition.predicted_end_date) - new Date(this.currentCompetition.predicted_start_date)) / (1000 * 3600 * 24)) +1;
    },
  },
  methods:{
    async name_change(newValue){
      await this.$showSaver();
      var result = await CompetitionsConfigurationHelper.renameCompetitionAsync(this.competition_id, newValue);
      this.currentCompetition.name = newValue;
      this.toastAsyncSaved(result, 'Compétition renommée.')
      this.$hideSaver();
    },
    async location_change(newValue){
      await this.$showSaver();
      var result = await CompetitionsConfigurationHelper.changeCompetitionLocationAsync(this.competition_id, newValue);
      this.currentCompetition.location = newValue;
      this.toastAsyncSaved(result, 'Lieu de la compétition modifié.')
      this.$hideSaver();
    },
    async isOpen_change(newValue){
      await this.$showSaver();
      var result = await CompetitionsConfigurationHelper.changeIsOpenFlagAsync(this.competition_id, newValue);
      this.currentCompetition.isOpen = newValue;
      this.toastAsyncSaved(result, 'Etat "open" modifié.');
      this.$hideSaver();
    },
    async startDate_change(newValue){
      console.log('statDate_change', newValue);
    },
    async endDate_change(newValue){
      console.log('endDate_change', newValue);
    },
    async registrationMode_change(newValue){
      await this.$showSaver();
      var result = await CompetitionsConfigurationHelper.changeRegistrationMode(this.competition_id, newValue);
      this.currentCompetition.registrationMode = newValue;
      this.toastAsyncSaved(result, "Modalité d'inscription modifiée.");
      this.$hideSaver();
    },
    async refresh(){
      await this.$showRefresher();
      this.dataSynchronized = await CompetitionsConfigurationHelper.refreshGeneralData(this.competition_id);
      //this.currentCompetition = RST_COMPETITION.query().where("id", this.competition_id).first();
      this.$hideRefresher();
    }
  },
  async mounted(){
    //console.log('GeneralTabs mounted');
    await this.refresh();
  }
}
</script>
