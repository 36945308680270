<template>
    <div>
      <errors-viewer :errors="roundErrors"></errors-viewer>

      <form-field caption="Chef juge de la manche">
        <b-select :value="chiefJudge" @change="chiefJudge_change" :disabled="disabled || ROUND.isBriefingCompleted">
          <option v-for="judge in presentJudges" :key="judge.id" :value="judge.staff_id">{{ judge.STAFF_MEMBER.PEOPLE.name }}</option>
        </b-select>
      </form-field>

      <form-field caption="Ordre de passage des compétiteurs">
        <b-radio-group plain v-if="false"
          v-model="competitorsOrderRule"
          buttons
          button-variant="outline-secondary"
          @change="competitorsOrderRule_change"
          :disabled="disabled || ROUND.isBriefingCompleted"
        >
          <b-form-radio :value="ExecOrderRuleEnum.Alea">&nbsp;<b-icon icon="dice5" size="lg" />&nbsp;Aléatoire</b-form-radio>
          <b-form-radio :value="ExecOrderRuleEnum.PrevRoundReverse" v-if="ROUND.round_number > 1">&nbsp;<b-icon icon="sort-up" size="sm" />&nbsp;Ordre inverse de la manche précédente</b-form-radio>
          <b-form-radio :value="ExecOrderRuleEnum.Rank" v-if="ROUND.round_number > 1">&nbsp;<b-icon icon="sort-numeric-down" size="sm" />&nbsp;Classement de la manche précédente (1..n)</b-form-radio>
          <b-form-radio :value="ExecOrderRuleEnum.RankReverse" v-if="ROUND.round_number > 1">&nbsp;<b-icon icon="sort-numeric-down-alt" size="sm" />Classement inverse de la manche précédente (n..1)</b-form-radio>
          <b-form-radio :value="ExecOrderRuleEnum.Subscription">&nbsp;<b-icon icon="list-ol" size="sm"/>Ordre des inscriptions</b-form-radio>
          <b-form-radio :value="ExecOrderRuleEnum.Alpha">&nbsp;<b-icon icon="sort-alpha-down" size="sm" />&nbsp;Ordre alphabétique</b-form-radio>
          <b-form-radio :value="ExecOrderRuleEnum.AlphaReverse">&nbsp;<b-icon icon="sort-alpha-down-alt" size="sm"/>Ordre alphabétique inversé</b-form-radio>
        </b-radio-group>
<!--        <b-select :value="competitorsOrderRule" @change="competitorsOrderRule_change" :options="possibleCompetitorsOrderRules" :disabled="disabled">
        </b-select>-->
        <b-select :value="competitorsOrderRule" @change="competitorsOrderRule_change" :disabled="disabled || ROUND.isBriefingCompleted">
          <option v-for="possibleCompetitorsOrderRule in possibleCompetitorsOrderRules" :key="possibleCompetitorsOrderRule.value" :value="possibleCompetitorsOrderRule.value" v-html="possibleCompetitorsOrderRule.html" />
        </b-select>
      </form-field>

      <form-field label="Règles de lissage des notes" v-if="false">
        <template #info-helper-placeholder>
          <info-helper>
            Règles applicables uniquement si le nombre de juges affectés à une épreuve est suffisant.
          </info-helper> 
        </template>
        <b-checkbox switch :checked="applySkipWorseNoteRule" @change="applySkipWorseNoteRule_change" :disabled="disabled || ROUND.isBriefingCompleted">Eliminer la moins bonne note</b-checkbox>
        <b-checkbox switch :checked="applySkipBestNoteRule" @change="applySkipBestNoteRule_change" :disabled="disabled || ROUND.isBriefingCompleted" style="margin-left: 15px;">Eliminer la meilleure note</b-checkbox>
      </form-field>    

      <slot name="postpend"></slot>
    </div>
</template>

<script>
import { /*RST_ROUND,*/ RST_COMPETITION_STAFF, /*SEASON_COMPULSORY, REF_COMPULSORY, RST_LEVEL, /*RST_ROUND_COMPULSORY*/ } from "@/LocalDB";

import RoundsConfigurationHelper from '@/services/helpers/roundsConfigurationHelper';
import FormField from '@/components/Panels/FormField.vue';
import InfoHelper from "@/components/InfoHelper.vue";
import { CompetitorExecOrderRuleEnum } from '@/Constants';
import ErrorsViewer from "../ErrorsViewer.vue";

export default{

    components:{FormField, InfoHelper, ErrorsViewer },
    props:{
        competition_id: {type:String, required:true},
        //round_number: { type:Number, required:true},
        ROUND: { type: Object, required:true },
        disabled: { type:Boolean, default: false}
    },
    data(){
        return {
            d_competition_id: this.competition_id,
            //d_round_number: this.round_number,

            //ROUND: null,
            presentJudges: [],

            data_synchronized: false,

            chiefJudge: null,
        }
    },
    computed:{
      roundErrors(){
        return this.ROUND != null
            ? this.ROUND.configurationErrors 
            : [];
      },
      ExecOrderRuleEnum(){
        console.log('ExecOrderRuleEnum', CompetitorExecOrderRuleEnum);
        return CompetitorExecOrderRuleEnum;
      },
      possibleCompetitorsOrderRules(){
        var ret = [];
        ret.push({ value: CompetitorExecOrderRuleEnum.Alea, html: '<b-icon icon="dice5" size="lg" />&nbsp;Ordre aléatoire' });
        if(this.ROUND.round_number > 1){
          ret.push({ value:CompetitorExecOrderRuleEnum.PrevRoundReverse, html: '<b-icon icon="sort-up" size="sm" />&nbsp;Ordre inverse de la manche précédente' });
          ret.push({ value:CompetitorExecOrderRuleEnum.Rank, html: '<b-icon icon="sort-numeric-down" size="sm" />&nbsp;Classement de la manche précédente (1..n)' });
          ret.push({ value:CompetitorExecOrderRuleEnum.RankReverse, html: '<b-icon icon="sort-numeric-down-alt" size="sm" />&nbsp;Classement inverse de la manche précédente (n..1)' });
        }
        ret.push({ value: CompetitorExecOrderRuleEnum.Subscription, html:'<b-icon icon="list-ol" size="sm"/>&nbsp;Ordre des inscriptions' });
        ret.push({ value: CompetitorExecOrderRuleEnum.Alpha, html: '<b-icon icon="sort-alpha-down" size="sm" />&nbsp;Ordre alphabétique' });
        ret.push({ value: CompetitorExecOrderRuleEnum.AlphaReverse, html: '<b-icon icon="sort-alpha-down-alt" size="sm"/>&nbsp;Ordre alphabétique inversé' })
        return ret;
      },
      /*chiefJudge(){
        return this.ROUND != null ? this.ROUND.chiefJudge_staff_id : '';
      },*/
      competitorsOrderRule(){
        return this.ROUND != null ? this.ROUND.competitors_order_rule : '';
      },
      applySkipWorseNoteRule(){
        return this.ROUND != null ? this.ROUND.applySkipWorseNoteRule : false;
      },
      applySkipBestNoteRule(){
        return this.ROUND != null ? this.ROUND.applySkipBestNoteRule : false;
      },
    },
    methods:{
      async chiefJudge_change(newValue){
        await this.showSaver();
        try{
          var result = await RoundsConfigurationHelper.changeRoundChiefJudgeAsync(this.d_competition_id, this.ROUND.round_number, newValue);
          this.ROUND.chiefJudge_staff_id = newValue;
          this.toastAsyncSaved(result, "Chef juge modifié");
        }catch(error){
          this.toastSaveFailure(error);
        }
        this.$hideSaver();
      },
      async competitorsOrderRule_change(newValue){
        await this.showSaver();
        try{
          var result = await RoundsConfigurationHelper.changeExecutionOrderRuleAsync(this.d_competition_id, this.ROUND.round_number, newValue);
          this.ROUND.competitors_order_rule = newValue;
          this.toastAsyncSaved(result, "Ordre de passe modifié");
        }catch(error){
          this.toastSaveFailure(error);
        }
        this.$hideSaver();
      },
      async applySkipWorseNoteRule_change(newValue){
        await this.showSaver();
        try{
          var result = await RoundsConfigurationHelper.changeApplySkipWorseNoteRuleAsync(this.d_competition_id, this.ROUND.round_number, newValue);
          this.ROUND.applySkipWorseNoteRule = newValue;
          this.toastAsyncSaved(result, "Règle de lissage des notes modifiée");
        }catch(error){
          this.toastSaveFailure(error);
        }
        this.$hideSaver();
      },
      async applySkipBestNoteRule_change(newValue){
        await this.showSaver();
        try{
          var result = await RoundsConfigurationHelper.changeApplySkipBestNoteRuleAsync(this.d_competition_id, this.ROUND.round_number, newValue);
          this.ROUND.applySkipBestNoteRule = newValue;
          this.toastAsyncSaved(result, "Règle de lissage des notes modifiée");
        }catch(error){
          this.toastSaveFailure(error);
        }
        this.$hideSaver();
      },
      async refresh(){
          await this.showRefresher();
          this.data_synchronized = false;
          //this.ROUND = RST_ROUND.query().where("competition_id", this.d_competition_id).where("round_number", this.ROUND.round_number).first();
          this.presentJudges = RST_COMPETITION_STAFF.query()
                        .where("competition_id", this.d_competition_id)
                        .where(staffMember => { return staffMember.CAPABILITIES.isJudge})
                        .get();
          this.chiefJudge = this.ROUND.chiefJudge_staff_id;
          this.hideRefresher()
      },
    },
    async mounted(){
        await this.refresh();
        this.hideLoader();
    },
    watch:{
        competition_id: function(newValue){
            this.d_competition_id = newValue;
            this.refresh();
        },
        ROUND: function(){
          this.refresh();
        }
        /*
        round_number: function(newValue){
          this.d_round_number = newValue;
          this.refresh();
        },*/
    }
}
</script>

<style scoped>
  select:disabled{ color:black !important; }
</style>