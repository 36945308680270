<template>
  <div>
    <data-sync-state v-model="dataSynchronized" data="d'épreuves" @refresh="refresh"/>

    <slot name="prepend"></slot>
    
    <events-admin-fr-cdf-cva
      :competition_id="d_competition_id"
      :regulation="d_regulation"
      @dataSynchronisationStateChange="EventsStateChange"
      @EventsChange="events_change"
      :disabled = "d_isDisabled"
    >
    </events-admin-fr-cdf-cva>
  </div>
</template>

<script>
import { RST_COMPETITION } from '@/LocalDB';
import DataSyncState from '@/components/Utilities/DataSyncState.vue';
/*import FormField from '@/components/Panels/FormField.vue';
import NumberOfRoundsEditor from '@/components/NumberOfRoundsEditor.vue';
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';*/
import EventsAdminFrCdfCva from './EventsAdminFrCdfCva2.vue';

export default{
  emits: ['EventsChange'],
  model:{
    prop: 'competition_id',
  },
  props:{
    competition_id: { type:String, required:true },
    regulation: { type:String, required: true },
    disabled: { type:Boolean, default: false },
  },
  components: { /*FormField, NumberOfRoundsEditor, */DataSyncState, EventsAdminFrCdfCva, },
  data(){
    return {
      d_competition_id: this.competition_id,
      COMPETITION: null,
      d_regulation: this.regulation,
      dataSynchronized: null,
      d_isDisabled: this.disabled,
 /*     nbPrecision: 0,
      nbBallet: 0,
      nbMix: 0,
      precisionAvailable: true,
      balletAvailable: true,
      mixAvailable: false,
      precisionEvents:[],*/
    }
  },
  methods:{
    async refresh(){
      await this.$showRefresher();
      this.COMPETITION = RST_COMPETITION.query().where('id', this.d_competition_id).first();
      this.regulation = this.COMPETITION.regulation;
      this.$hideRefresher();
    },
    EventsStateChange(state){
      this.dataSynchronized = state;
      this.$emit('EventsChange');
    },
    events_change(){
      this.$emit('EventsChange');
    }
  },
  async mounted(){
    await this.refresh();
  },
  watch:{
    competition_id: function(newVal, oldVal){
      if(newVal != oldVal)
      {
        this.d_competition_id = newVal;
        this.refresh();
      }
    },
    regulation: function(newVal){
      this.d_regulation = newVal;
      this.refresh();
    },
    disabled: function(newVal){
      this.d_isDisabled = newVal;
    }
  }
}
</script>
