<template>
  <div>
    <b-container fluid>
      <b-row style="display:flex; justify-content:center" v-if="!overAllCompleted">
        <b-button @click="refresh">Vérifier l'état des épreuves</b-button>
      </b-row>
      <b-row style="display:flex; justify-content:center; justify-items: center; flex-wrap: wrap;" v-for="level in levels" :key="level.key" align-h="center">
        <div 
          v-for="event in level.events"
          :key="event.key" 
          class="-container -fluid"
        >
          <b-button
            class="event-launcher-palet event"
            :variant="event.variantTheme + '-' + level.code" 
            :disabled="event.disabled"
            @click="launch(event)">
              <icon-event-type :value="event.event" :variant="level.code" style="display:inline-block" />
              <icon-category :value="event.category" :variant="level.code" style="display:inline-block" />
              <div v-html="event.variantText"></div>
          </b-button>
        </div>
      </b-row>
      <b-row style="display:flex; justify-content:center" v-if="!overAllCompleted">
        <b-button @click="refresh">Vérifier l'état des épreuves</b-button>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { RST_LEVEL, RST_REF_EVENT, RST_REF_CATEGORY, RST_EVENT, /*RST_ROUND*/} from '@/LocalDB';
//import CollapsePanel from '@/components/Panels/CollapsePanel.vue'
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import EventsConfigurationHelper from '@/services/helpers/eventsConfigurationHelper';
//import EventLauncher from './EventLauncher.vue';
import IconCategory from "@/components/IconCategory.vue";
import IconEventType from "@/components/IconEventType.vue";

export default{
  props: {
    //round_number: { type: Number, required:true },
    round: { type: Object, required:true },
    waitMode: { type: Boolean, default: false},
  },
  components: { /*CollapsePanel,*/ /*EventLauncher,*/ IconCategory, IconEventType },
  computed:{
  /*  currentRound(){
      return RST_ROUND.query().where('competition_id', this.currentCompetition.id).where('round_number', this.round_number).first();
    }*/
  },
  data(){
    return {
      //d_round_number: this.round_number,
      dataSynchronized: false,
      levels: [],

      consoleLogOn: true,
      waitIntervalId: null,
      d_waitMode: this.waitMode,
      overAllCompleted: false,
    }
  },
  methods:{
    async startWaitForEvent(emitEvent=false){
      if(!this.overAllCompleted){
        if(this.waitIntervalId == null || this.waitIntervalId == 0){
          this.waitIntervalId = this.round.round_number * 10;//this.waitIntervalId = setInterval(async function(){ await this.refresh();}.bind(this), 5000);
          if(this.consoleLogOn) console.log('start interval', this.waitIntervalId);
          this.d_waitMode = true;
          if(emitEvent)
            this.$emit('startWaitingForEvent')
        }
      }
    },
    async stopWaitForEvent(emitEvent=false){
      if(this.consoleLogOn)console.log('stopWaitForEvent', this.waitIntervalId);
      clearInterval(this.waitIntervalId);
      this.waitIntervalId = null;
      if(this.consoleLogOn) console.log('cleared', this.waitIntervalId, 'emitEvent', emitEvent);
      this.d_waitMode = false;
      if(emitEvent)
        this.$emit('stopWaitingForEvent');
    },
    stopWait(){
      if(this.consoleLogOn)console.log('stopWait', this.waitIntervalId);
      clearInterval(this.waitIntervalId);
      this.waitIntervalId = null;
      if(this.consoleLogOn)console.log('cleared', this.waitIntervalId);
      this.d_waitMode = false;
    },
    async showResults(evt){
      this.setCurrentCompetition_id(this.currentCompetition.id);
      this.setCurrentRound_number(this.round.round_number);
      this.setCurrentLevel_code(evt.level);
      this.setCurrentCategory_code(evt.category);
      this.setCurrentEvent_code(evt.event);      
      this.$router.push({ name: 'EventResults' });
    },
    async launch(evt)
    {
      await this.stopWaitForEvent(true);
      if(evt.action == 'showResults'){
        return await this.showResults(evt);
      }
      await this.showLoader();
    
      var result = await EventsConfigurationHelper.getAllDataForNotationAsync(this.currentCompetition.id, this.round.round_number, evt.level, evt.category, evt.event);

      result = await EventsConfigurationHelper.ensureEventExistsAsync(this.currentCompetition.id, this.round.round_number, evt.level, evt.category, evt.event);
      if(!result.success){
        await this.$bvModal.msgBoxOk("L'épreuve a été créée localement mais la synchronisation avec le serveur ne s'est pas déroulée correctement.", { headerBgVariant: 'warning', okVariant: 'warning'});
      }
      if(result.event.isCompleted)
      {
        await this.$bvModal.msgBoxOk("Le lancement d'une épreuve terminée n'est pas implémenté !");
      } else {
        this.setCurrentCompetition_id(this.currentCompetition.id);
        this.setCurrentRound_number(this.round.round_number);
        this.setCurrentLevel_code(evt.level);
        this.setCurrentCategory_code(evt.category);
        this.setCurrentEvent_code(evt.event);

        if(result.event.isStarted)
        {
          var chiefJudgeVisa = evt.CHIEF_JUDGE != null ? evt.CHIEF_JUDGE.visa : null;
          if(this.consoleLogOn)console.log('chiefJudgeVisa vs currentUser.visa', )
          if(!this.currentUser.visa != chiefJudgeVisa)
          {
            this.$router.push({ name: 'EventRun' });
            return;
          }
          if (await(this.$bvModal.msgBoxConfirm('La notation de cette épreuve est en cours... Voulez-vous la continuer (oui) ou revenir à la configuraiton (non)', { okVariant: 'primary', okTitle:'Oui', cancelVariant: 'danger', cancelTitle: 'non'}))) {
            this.$router.push({ name: 'EventRun' });
            return;
          }
        }
        this.$router.push({ name: 'EventPrepare' });
      }
    },
    async refresh(){
      var compet_id = this.currentCompetition.id;
      this.dataSynchronized = await CompetitionsConfigurationHelper.refreshLauncherMapDataAsync(compet_id);
      //var roundReadyState = this.currentRound.isReadyToProcess && this.currentRound.isBriefingCompleted;
      var roundReadyState = this.round.isReadyToProcess && this.round.isBriefingCompleted;

      var levels = RST_LEVEL.query().where("competition_id", compet_id).get();
      
      this.overAllCompleted = true;

      this.levels = levels.map(rstLevel => {

        var levelCaption = levels.length > 1 ? ' ' + rstLevel.LEVEL.name : '';

        var allEventsTypes = RST_REF_EVENT.query().where('competition_id', compet_id).where("level", rstLevel.level).where("round_number", this.round.round_number).orderBy("displayOrder").get();
        var allCategories = RST_REF_CATEGORY.query().where('competition_id', compet_id).where("level", rstLevel.level).where(cat => cat.effective_competitors_count > 0).orderBy(c => c.CATEGORY.displayOrder).get();
        var allLevelEvents = [];

        var allCompleted = false;
        var noneStarted = true;

        allEventsTypes.forEach(evtType =>{
          allCategories.forEach(cat => {
              var evt = RST_EVENT.query().where("competition_id", compet_id).where("level", evtType.level).where("round_number", this.round.round_number).where("category", cat.category).where('event', evtType.event).first();
              if(evt == null){
                evt = new RST_EVENT();
                evt.competition_id = compet_id;
                evt.level = evtType.level;
                evt.event = evtType.event;
                evt.category = cat.category;
                evt.round_number = this.round.round_number;
              }
              var disabled = false;
              // check disabled vs previous round
              if(!disabled && evt.round_number > 1 && !evt.isCompleted)
              {
                var prevEvent = evt.PREVIOUS_ROUND_EVENT;
                disabled = prevEvent != null && !prevEvent.isCompleted;
                if(this.consoleLogOn) console.log('disabled 0', disabled);
              }
              // check disabled vs same category
              if(!disabled)
              {
                disabled = RST_EVENT.query().where("competition_id", evt.competition_id).where("round_number", evt.round_number).where("level", evt.level).where("category", evt.category).where(e => e.event != evt.event && e.isStarted && !e.isCompleted).exists();
              }

              // check disabled vs user role
              var isUserRoleEnabled = false;
              //var waitToJoinAsStaffMember = false;
              if(!disabled && !evt.isCompleted){
                disabled = true;
                var userVisa = this.currentUser.visa;
                if(this.consoleLogOn) console.log('userVisa', userVisa, this.currentCompetition.ADMINS);
                if(userVisa == this.currentCompetition.owner) disabled = false;
                else if(this.currentCompetition.ADMINS.findIndex(a => a.visa == userVisa) >= 0) disabled = false;
                else if(evt.STAFF_MEMBERS.findIndex(sm => sm.visa == userVisa) >= 0 && (evt.isBriefingCompleted || evt.isStarted)){
                  disabled = false;
                  //waitToJoinAsStaffMember = true;
                }
                if(this.consoleLogOn) console.log('disabled', disabled);
                isUserRoleEnabled = !disabled;
              }
              else
              evt.disabled = disabled;
              
              
              if( evt.isCompleted){
                evt.variantText = "Résultats " + evt.EVENT.shortname + " " + evt.round_number + " " + evt.CATEGORY.shortname + levelCaption ;
                evt.variantTheme = "success";
                evt.action = "showResults";
                evt.disabled = false;
                noneStarted = false;
              } else if (evt.isStarted)
              {
                allCompleted = false;
                evt.variantText = "Reprendre " + evt.EVENT.shortname + " " + evt.round_number + " " + evt.CATEGORY.shortname + levelCaption;
                evt.variantTheme = "warning";
                evt.action = "continueEvent";
              }
              else if(!roundReadyState) {
                evt.variantTheme = "primary";
                evt.disabled = !isUserRoleEnabled;
                evt.variantText = "Préparer " + evt.EVENT.shortname + " " + evt.round_number + "<br/>" + evt.CATEGORY.shortname + levelCaption;
                if(this.consoleLogOn) console.log('disabled 3', evt.disabled);
              } else{
                allCompleted = false;
                noneStarted = false;
                evt.variantText = "Démarrer " + evt.EVENT.shortname + " " + evt.round_number + "<br/>" + evt.CATEGORY.shortname + levelCaption;
                evt.variantTheme = "primary";
                evt.action = "startEvent";
              }

            if(evt.disabled) { evt.variantTheme = "outline-danger"; /*evt.variantText = 'DIS_' + evt.variantText;*/}
              allLevelEvents.push(evt);            
          })
        })
        
        if(this.consoleLogOn) console.log("setting completion", { round_number: this.round.round_number, level: rstLevel.code, waitIntervalId: this.waitIntervalId, overAllCompleted: this.overAllCompleted, allCompleted: allCompleted, rstLevel: rstLevel})
        this.overAllCompleted &= allCompleted;

        return { ...rstLevel, ...rstLevel.LEVEL,
          isCompleted: allCompleted,
          isStarted: !noneStarted,
          variantTheme: rstLevel.isCompleted ? 'success' : (rstLevel.isStarted ? 'warning' : 'secondary'),
          variantText: rstLevel.Name + rstLevel.isCompleted ? ' - Terminé' : (rstLevel.isStarted ? ' - En cours': ''),
          events: allLevelEvents,
        };
      });

      if(!this.overAllCompleted){
         /* if(this.waitIntervalId == null || this.waitIntervalId == 0){
            this.waitIntervalId = setInterval(async function(){ await this.refresh();}.bind(this), 10000);
            console.log('start interval', this.waitIntervalId);
          }*/
          await this.startWaitForEvent(true)
        } else {
          await this.stopWaitForEvent();
        }

    },
  },
  async mounted(){
    await this.refresh();
  },
  watch:{
    '$route' (to, from){
      if(this.consoleLogOn) console.log('RoundEventsLauncher watch $route', {to: to, from: from});
      this.stopWait();
    },
    waitMode: async function(newValue){
      if(this.consoleLogOn) ('RoundEventsLauncher watch waitMode', {round_number: this.round.round_number, newValue: newValue, d_waitMode: this.d_waitMode, waitIntervalId: this.waitIntervalId});
      if(newValue != this.d_waitMode)
        if(newValue)
          await this.startWaitForEvent();
        else
          await this.stopWaitForEvent();
    },

  }
  /*watch:{
    round: async function(newVal, oldVal){
      await this.refresh();
    }
  }*/
}
</script>
