<template>
  <div>
    <data-sync-state v-model="dataSynchronized" data="de terrain" @refresh="refresh"/>

    <b-tabs card pills :vertical="verticalTabs">
      <b-tab v-for="field in fields" :key="field.id" :title="field.field.field_number + '-' + field.field.field_name">
        <template #title>
          <b-badge pill size="sm" :variant="field.field.isMain ? '' : 'info'">{{field.field.field_number}}</b-badge> {{ field.field.field_name}}
        </template>
        <field-editor
          :value="field.field"
          :disabled="d_disabled"
          :canSetHotState="field.canChangeHotstate"
          :isRemovable="field.isRemovable"
          @fieldName_change="fieldName_change"
          @size_change="fieldSize_change"
          @isSonorized_change="fieldIsSonorized_change"
          @stageIn_change="fieldStageIn_change"
          @stageOut_change="fieldStageOut_change"
          @hotState_change="fieldHotState_change"
          @removeField="removeField"
        />
      </b-tab>
      <template #tabs-end>
        <b-nav-item v-if="!d_disabled" role="presentation" @click="addField" href="#"><i>Ajouter un terrain</i></b-nav-item>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import { RST_FIELD } from "@/LocalDB";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import FieldEditor from '@/components/fieldEditor.vue';
import DataSyncState from '@/components/Utilities/DataSyncState.vue';

export default{
  model:{
    prop: 'competition_id',
  },
  props:{ 
    competition_id: { type:String, required:true },
    disabled: { type:Boolean, default: false }
  },
  data(){
    return {
      dataSynchronized: null ,
      fields:[],
      d_disabled: this.disabled,
    };
  },
  components:{ FieldEditor, DataSyncState },
  computed:{
 /*   fields(){
      var list = RST_FIELD.query()
        .where("competition_id", this.competition_id)
        .orderBy("field_number")
        .get()
        .map(item => {
          return { field: item, isRemovable: !item.isMain, canChangeHotState : false }
        });
      console.log('fields', list);
      return list;
    },*/
    verticalTabs(){
      return !this.isSmallWidth;
    }
  },
  methods:{
        async addField(){
          await this.$showSaver();
          CompetitionsConfigurationHelper.addField(this.currentCompetition_id);
          this.toastSaved("Terrain ajouté.");
          this.$hideSaver();
          await this.refresh();
        },
        async removeField(field_number) {
          await this.$showSaver();
          CompetitionsConfigurationHelper.removeField(this.currentCompetition_id, field_number);
          this.toastSaved("Terrain supprimé.");
          this.$hideSaver();
          await this.refresh();
        },
        async fieldName_change(field_number, newValue){
          await this.$showSaver();
          CompetitionsConfigurationHelper.setFieldName(this.currentCompetition_id, field_number, newValue);
          this.toastSaved("Le renommage du terrain a réussi.");
          this.$hideSaver();
        },
        async fieldSize_change(field_number, newSize_A, newSize_B)
        {
          await this.$showSaver();
          CompetitionsConfigurationHelper.setFieldSize(this.currentCompetition_id, field_number, newSize_A, newSize_B);
          this.toastSaved("Le redimensionnement du terrain a réussi.");
          this.$hideSaver();
        },
        async fieldIsSonorized_change(field_number, newValue){
          await this.$showSaver();
          CompetitionsConfigurationHelper.setFieldIsSonorized(this.currentCompetition_id, field_number, newValue);
          this.toastSaved("Modification de la sonorisation du terrain effectuée.");
          this.$hideSaver();
        },
        async fieldStageIn_change(field_number, newValue){
          await this.$showSaver();
          CompetitionsConfigurationHelper.setFieldStageIn(this.currentCompetition_id, field_number, newValue);
          this.toastSaved("Modification du Stage-IN effectuée.");
          this.$hideSaver();
        },
        async fieldStageOut_change(field_number, newValue){
          await this.$showSaver();
          CompetitionsConfigurationHelper.setFieldStageOut(this.currentCompetition_id, field_number, newValue);
          this.toastSaved("Modification du Stage-OUT effectuée.");
          this.$hideSaver();
        },
        async fieldHotState_change(field_number, newValue){
          await this.$showSaver();
          CompetitionsConfigurationHelper.setFieldHotState(this.currentCompetition_id, field_number, newValue);
          this.toastSaved();
          this.$hideSaver();
        },
        async refresh(){
          await this.$showRefresher();
          this.dataSynchronized = await CompetitionsConfigurationHelper.refreshFields(this.competition_id);
          this.fields = RST_FIELD.query()
            .where("competition_id", this.competition_id)
            .orderBy("field_number")
            .get()
            .map(item => {
              return { field: item, isRemovable: !item.isMain, canChangeHotState : false }
            });
          this.$hideRefresher();
        },
  },
  async mounted(){
    console.log('FieldTabs mounted');
    await this.refresh();
    /*await CompetitionsConfigurationHelper.refreshDataFromServer()
      .then(ok => this.dataSynchronized = ok);*/
  },
  watch:{
    disabled: function(newValue) { this.d_disabled = newValue; },
  }
}
</script>
