<template>
    <div>
        <competition-header
            v-model="currentCompetition"
            :showErrors="false"
            :showWarnings="false"
            :showBackButton="false"
        >
        </competition-header>

        <collapse-panel title="Compétition - configuration générale">
          <div>&nbsp;</div>
          <b-tabs v-model="activeTab" fill>
            <b-tab title="Generalités" lazy @click="showRefresher">
              <general-tab v-model="currentCompetition.id" :regulation="regulation" :disabled="!canChangeSettings"
                :canChangeStartDate="false"
                :canChangeEndDate="false"
              >
                <template #levels>
                  <form-field v-show="possibleLevels.length > 1" caption="Niveaux">
                      <b-form-checkbox-group
                        v-model="selectedLevels"
                          switches
                          >
                          <b-checkbox
                              v-for="level in possibleLevels"
                              :key="level.code"
                              :value="level"

                              :disabled="!canChangeSettings || level.cannotBeUnselected"
                              @change="selectedLevels_changed(level)"
                          >
                              {{ level.name }}
                          </b-checkbox>
                      </b-form-checkbox-group>
                  </form-field>
                </template>
                <template #registration-commands>
                  <b-check
                    v-model="isLockedForSelection"
                    button
                    button-variant="secondary"
                    v-if="isBasedOnSelection"
                    @change="isLockedForSelection_changed"
                     style="float:right"
                     :disabled ="!canChangeSettings || (isBasedOnRegistration && !isLockedForRegistration)"
                  >
                    {{ isLockedForSelection 
                            ? 'Modifier la sélection' : 'Ouvrir les inscriptions'
                    }}
                  </b-check>
                  <b-check
                    v-model="isLockedForRegistration"
                    button
                    button-variant="secondary"
                    v-if="isBasedOnRegistration"
                    @change="isLockedForRegistration_changed"
                     style="float:right"
                     :disabled ="!canChangeSettings"
                  >
                    {{ isLockedForRegistration 
                            ? (registrationMode == 'S' ? 'Modifier la sélection' : 'Ouvrir les inscriptions')
                            : (registrationMode == 'S' ? 'Terminer la sélection' : 'Fermer les inscriptions')
                    }}
                  </b-check>
                </template>
              </general-tab>
            </b-tab>
            <b-tab :title="level.name" v-for="level in levels" :key="level.code" lazy @click="showRefresher">
              <competitors-tab :level="level" :competition_id="currentCompetition.id" 
                :lockedForChanges="!canChangeSettings"
                :lockedForRegistration="isLockedForRegistration"
                :lockedForSelection="isLockedForSelection"
                @competitorsListChange="refreshLevels"
              >
                <template #levels>
                  <form-field v-if="possibleLevels.length > 1" style="float:right" >
                    <template #prepend><span style="color: var(--ffvlOrangePicto); margin-right: 10px;">Niveaux : &nbsp;</span></template>
                      <b-form-checkbox-group
                        v-model="selectedLevels"
                          switches
                          >
                          <b-checkbox
                              v-for="level in possibleLevels"
                              :key="level.code"
                              :value="level"

                              :disabled="!canChangeSettings || level.cannotBeUnselected"
                              @change="selectedLevels_changed(level)"
                          >
                              {{ level.name }}
                          </b-checkbox>
                      </b-form-checkbox-group>
                  </form-field>
                </template>
                <template #tab-head-commands>
                  <b-check
                    v-model="isLockedForSelection"
                    button
                    button-variant="secondary"
                    v-if="isBasedOnSelection"
                    @change="isLockedForSelection_changed"
                    style="float:right"
                    :disabled="!canChangeSettings || (isBasedOnRegistration && !isLockedForRegistration)"
                  >
                    {{ isLockedForSelection ? "Modifier la sélection" : "Terminer la sélection" }}
                  </b-check>
                  <b-check
                    v-model="isLockedForRegistration"
                    button
                    button-variant="secondary"
                    v-if="isBasedOnRegistration"
                    @change="isLockedForRegistration_changed"
                     style="float:right"
                     :disabled ="!canChangeSettings || (isBasedOnSelection && !isLockedForSelection)"
                  >
                    {{ isLockedForRegistration 
                            ? (registrationMode == 'S' ? 'Modifier la sélection' : 'Ouvrir les inscriptions')
                            : (registrationMode == 'S' ? 'Terminer la sélection' : 'Fermer les inscriptions')
                    }}
                  </b-check>
                </template>
              </competitors-tab>
            </b-tab>
            <b-tab title="Juges & staff" lazy @click="showRefresher">
              <staffmembers-tab v-model="currentCompetition" :disabled="!canChangeSettings" />
            </b-tab>
            <b-tab title="Terrains" lazy @click="showRefresher">
              <fields-tab v-model="currentCompetition.id" :disabled="!canChangeSettings"/>
            </b-tab>
            <b-tab title="Epreuves" lazy @click="showRefresher">
              <events-tab
                v-model="currentCompetition.id"
                :regulation="regulation"
                @EventsChange="events_change"
                :disabled="!canChangeSettings"
              >
                <template #prepend>
                  <b-row v-if="rounds.length > 1 && false">
                    <b-col md-8>
                      <form-field caption="Règle de calcul des résultats" required>
                        <b-form-radio-group
                          v-model="edtResultCalculationMode"
                          stacked switch required
                          @change="resultCalculationMode_change"
                          :disabled="!canChangeSettings"
                        >
                          <b-form-radio v-for="mode in listOfResultCalculationModes" :key="mode.key" :value="mode.value">{{ mode.text }}<info-helper v-show="mode.value.description != null && mode.value.description != ''">{{ mode.value.description }}</info-helper></b-form-radio>
                        </b-form-radio-group>
                      </form-field>
                    </b-col>
                    <b-col md-4>
                      <form-field caption="Nb. épreuves considérées">
  <!--                     <number-of-rounds-editor
                          v-model="edtNbRoundToSkipOrKeep"
                          :minRoundsToKeep="1"
                          :maxRounds="newNbRounds-1"
                          :canRemoveRound="true"
                          :canAddRound="true"
                          @addRound="addKeepableRounds"
                          @removeRound="removeKeepableRounds"
                        />-->
                      </form-field>
                    </b-col>
                  </b-row>
                </template>
              </events-tab>
            </b-tab>
          </b-tabs>
        </collapse-panel>

        <hr/>

        <collapse-panel v-for="round in rounds" :key="round.round_number" :title="'Tour/Manche' + round.round_number" lazy>
          <round-configuration
            :competition_id="currentCompetition.id"
            :ROUND="round"
            :disabled="!canChangeSettings"
          >
            <template #postpend>
              <round-launcher
                :round="round"
                :waitMode="d_waitMode"
                @startWaitingForEvent="d_waitMode=true"
                @endWaitingForEvent="d_waitMode=false"
              ></round-launcher>
            </template>
          </round-configuration>
        </collapse-panel>
        
        <b-button @click="launch('CompetitionResults')" style="width:100%; height:3rem;" ><b-icon icon="trophee"/> Résultats de la compétition & classement général</b-button>
        <b-button @click="showDump = true" :disabled="showDump" style="height:2rem;>">Dump des saisies BDD locale</b-button>
        <scores-dump :competition_id="currentCompetition.id" :show="showDump"></scores-dump>
        <b-button @click="showDump = false" v-if="showDump" style="height:2rem;>">Masquer le dump</b-button>

    </div>
</template>

<script>
import { RST_COMPETITION,  REF_LEVEL, SEASON_COMPETITION_TYPE_SCOPE_LEVEL, SEASON_COMPETITION_TYPE_CATEGORY_LEVEL, REF_RESULT_CALCULATION_MODE, /*RST_COMPETITOR_COMPOSITION,*/ /*COMPETITOR,*/ RST_LEVEL,RST_COMPETITOR, RST_ROUND} from "@/LocalDB";
//import { mapGetters } from 'vuex';
import CompetitionHeader from './components/CompetitionHeader';
import RoundLauncher from './components/Launchers/RoundLauncher.vue';

import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';

//import RoundsLauncher from "@/components/RoundsLauncher";
//import RoundsConfigurationHelper from "@/services/helpers/roundsConfigurationHelper";
//import NumberOfRoundsEditor from '@/components/NumberOfRoundsEditor.vue';


import GeneralTab from './components/EditWizard/GeneralTab.vue';
import CompetitorsTab from './components/EditWizard/CompetitorsTab.vue';
import StaffmembersTab from './components/EditWizard/StaffmembersTab2.vue';
import FieldsTab from './components/EditWizard/FieldsTab.vue';
import EventsTab from './components/EditWizard/EventsTab2.vue';
import FormField from '@/components/Panels/FormField.vue';
//import EventsLauncher from "./components/EventsLauncher.vue";
import InfoHelper from "@/components/InfoHelper.vue";
import CollapsePanel from "@/components/Panels/CollapsePanel.vue";
import RoundConfiguration from "./components/EditWizard/RoundConfiguration.vue"
//import NumberOfRoundsEditor from '@/components/NumberOfRoundsEditor.vue';
import ScoresDump from './components/scoresDump.vue';

export default {
    components: { CompetitionHeader, CollapsePanel, RoundConfiguration, RoundLauncher, ScoresDump, /*levelCategoriesSummary, DetailedCompetitorsList,*/ /*VueBootstrapTypeahead,*/ /*HelperNote,*//*RoundsLauncher,*/ /*NumberOfRoundsEditor,*/ /*IconCategory,*/ /*TeamMembersList,*/ /*ChooseOrEditPeopleModal,*/ /*ChooseOrEditPeople,*/ GeneralTab, CompetitorsTab, StaffmembersTab, FieldsTab, EventsTab, FormField, /*EventsLauncher ,*/ InfoHelper },
    data(){

       return {
         //show:false,
          isLockedForRegistration: false,
          isLockedForSelection: false,
          registrationMode: '',

          activeTab: 0,
          levels:[],
          possibleLevels:[],
          selectedLevels:[],
          rounds:[],

          showDump: false,
          activeRoundTab: 1,
          d_waitMode: false,
        }
    },
    computed: {
      canChangeSettings(){
        var compet = this.currentCompetition;
        if(compet.isLockedForChanges) return false;
        var currentUserVisa = this.currentUser ? (this.currentUser.visa || this.currentUser.username) : null;
        //console.log("currentUserVisa", currentUserVisa, this.currentUser);
        //console.log("compet", compet);
        if(compet.owner == currentUserVisa) return true;
        if(compet.ADMINS.findIndex(a => a.visa == currentUserVisa) >= 0) return true;
        return false;
      },
      edtResultCalculationMode(){
        return this.currentCompetition ? this.currentCompetition.resultCalculationMode : '';
      },
      roundsCount(){
        return this.currentCompetition ? this.currentCompetition.ROUNDS.length : 0;
      },      

        newRoundAverageComputationRule: {
            get(){return this.currentCompetition.roundAverageComputationRule},
            set(newValue){
              this.$showRefresher()
                .then(() => {
                  CompetitionsConfigurationHelper.changeRoundAverageComputationRule(this.currentCompetition_id, newValue);
                  this.$hideRefresher();
                });
              }
        },
        newNbRoundToSkipOrKeep :{
            get() { return this.currentCompetition.nbRoundToSkipOrKeep; },
            set(newValue){
              this.$showRefresher()
                .then(() => {
                  CompetitionsConfigurationHelper.changeNbRoundToSkipOrKeep(this.currentCompetition_id, newValue);
                  this.$hideRefresher();
                });
            }
        },
        nbRoundToSkipOrKeepVisible(){
            return this.newRoundAverageComputationRule != "averageOfAllRounds";
        },
        nbRoundToSkipOrKeepLabel(){
            if(this.newRoundAverageComputationRule == "averageOfNBestRounds")
                return "Nb. manches conservées";
            if(this.newRoundAverageComputationRule == "averageExcludingNWorseRounds")
                return "Nb. manches exclues";
            return "Nb. manches considérées";
        },
        regulation(){
          return this.currentCompetition.COMPETITION_TYPE.regulation;
        },
        listOfResultCalculationModes(){
          return REF_RESULT_CALCULATION_MODE.query()
              .where('regulation', this.regulation)
              .get()
              .map(mode => { return { value: mode.code, key: mode.code, text: mode.name}; });
        },
        isBasedOnSelection(){
          switch(this.registrationMode)
          {
            case "S":
            case "C":
              return true;
            default:
              return false;
          }
        },
        isBasedOnRegistration(){
          return this.registrationMode != 'S';
        }
    },
    methods:{
      async launch(path){
        await this.$showLoader();
        this.d_waitMode = false;
        this.$router.push({name: path})
      },
      async events_change(){
        await this.refreshRounds(true);
      },
//        ...mapGetters('modelViewsHelper', ['getDetailedStaffListItems'] ),
        async resultCalculationMode_change(newValue){
          if(newValue == '') return;
          await this.$showSaver();
          var result = await CompetitionsConfigurationHelper.changeResultCalculationModeAsync(this.currentCompetition_id, newValue);
          //this.currentCompetition.resultCalculationMode = newValue;
          this.toastAsyncSaved(result, 'Mode de calcul du résultat final modifié');
          this.$hideSaver();
        },
        async refreshLevels(checkServerData = true){
          if(checkServerData)
            await CompetitionsConfigurationHelper.refreshLevels(this.currentCompetition_id);
            this.possibleLevels = [];
            SEASON_COMPETITION_TYPE_SCOPE_LEVEL.query()
                .where('year', this.currentCompetition.year)
                .where('competition_type', this.currentCompetition.competition_type)
                .where('scope', this.currentCompetition.scope)
                .get()
                .map(item => {
                  return item.level
                })
                .filter((value, index, self) => { return self.indexOf(value) === index})
                .forEach(code => {
                    var toPush = REF_LEVEL.query().where('code', code).first();
                    toPush.cannotBeUnselected = RST_COMPETITOR.query().where("competition_id", this.currentCompetition.id).where("level", code).exists(),
                    this.possibleLevels.push(toPush);
                });
            if(this.possibleLevels.length == 1)
            {
              this.possibleLevels[0].name = 'Compétiteurs';
              this.possibleLevels[0].isSelected = true;
              this.possibleLevels[0].cannotBeUnselected = true;
              this.levels = this.possibleLevels;
              this.addableLevels = [];
            } else {
              this.selectedLevels = RST_LEVEL.query()
                .where('competition_id', this.currentCompetition.id)
                .get()
                .map(item => item.level )
                .filter((value, index, self) => { return self.indexOf(value) === index})
                .map(item => { var itm = REF_LEVEL.query().where("code", item).first();
                    itm.cannotBeUnselected = RST_COMPETITOR.query().where("competition_id", this.currentCompetition.id).where("level", item).where("isEffective", true).exists();
                    return itm;
                });

              this.levels = this.possibleLevels.filter(item => {
                return this.selectedLevels.findIndex(l => l.code == item.code) >= 0;
              });
              this.addableLevels = this.possibleLevels.filter(item => {
                return this.selectedLevels.findIndex(l => l.code == item.code) == -1;
              });
            }
        },
        async refreshRounds(checkServerData = true){
          await this.$showRefresher();
          //console.log('refreshRounds');
          if(checkServerData)
            await CompetitionsConfigurationHelper.refreshRounds(this.currentCompetition.id);
          this.rounds = RST_ROUND.query().where("competition_id", this.currentCompetition.id).orderBy('round_number').get();
          this.$hideRefresher();
        },
        async refresh(){
          await this.showRefresher();
          await CompetitionsConfigurationHelper.refreshCompetition(this.currentCompetition_id);
          var COMPETITION = RST_COMPETITION.query().where("id", this.currentCompetition_id).first();
          this.isLockedForRegistration = COMPETITION.isLockedForRegistration;
          this.isLockedForSelection = COMPETITION.isLockedForSelection;
          this.registrationMode = COMPETITION.registrationMode;
          await this.refreshLevels(false);
          await this.refreshRounds(false);
          this.$hideRefresher();
        },

        async isLockedForRegistration_changed(newValue){
          await this.$showSaver();
          try{
            var result = await CompetitionsConfigurationHelper.changeLockForRegistrationAsync(this.currentCompetition_id, newValue);
            this.currentCompetition.isLockedForRegistration = newValue;
            //var msg = '';
            //switch(this.registrationMode){
              //case 'S':
                //msg = newValue ? 'Sélection close' : 'Sélection ouverte en modification';
                //break;
              //case 'R':
              //case 'A':
              //  msg = newValue ? 'Inscriptions closes' : 'Inscriptions ouvertes';
              //  break;
           //}
            var msg = newValue ? "Inscriptions closes" : "Inscriptions ouvertes";
            this.toastAsyncSaved(result, msg);
          }catch(error)
          {
            this.toastSaveFailure(error);
          }
          this.$hideSaver();
        },

        async isLockedForSelection_changed(newValue)
        {
          await this.$showSaver();
          try{
            var result = await CompetitionsConfigurationHelper.changeLockForSelectionAsync(this.currentCompetition_id, newValue);
            this.currentCompetition.isLockedForSelection = newValue;
            var msg = newValue ? "Sélection close" : "Sélection ouverte en modification";
            this.toastAsyncSaved(result, msg);
          }catch(error)
          {
            this.toastSaveFailure(error);
          }
          this.$hideSaver();
        },
        async selectedLevels_changed(e){
          // recherche du niveau changé dans la liste des niveaux choisis
          var estASupprimer = this.selectedLevels.findIndex(l => l.code == e.code) < 0;
          await this.$showSaver();
          var success = false;
          if(estASupprimer)
          {
            // Le niveau vient d'être supprimé de la liste. Il faut donc le supprimer en base (avec tout ce qui va avec -> catégories & compétiteurs, notamment)
            // Cependant, on demande confirmation, au préalale si des compétiteurs ont déjà été ajoutés pour ce niveau.
            success = await CompetitionsConfigurationHelper.deleteLevel(this.currentCompetition_id, e.code);
            this.toastAsyncSaved(success, 'Niveau supprimé');
          } else {
            // Le niveau vient d'être ajouté. Il faut donc l'ajouter en base avec (avec tout ce qui va avec -> catégories)
            success = await CompetitionsConfigurationHelper.createLevel(this.currentCompetition_id, e.code);
            this.toastAsyncSaved(success, 'Niveau ajouté');
          }
          this.$hideSaver();
          await this.$showRefresher();
          await this.refreshLevels();
          this.$hideRefresher();
        },

        selectedLevelsCategory(levelCode){
            var ret = SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
                .where('year', this.currentCompetition.year)
                .where('competition_type', this.currentCompetition.competition_type)
                .where('level', levelCode)
                .get()
                .map(item => item.CATEGORY)
                .filter((value, index, self) => { return self.indexOf(value) === index})
            return ret;
        },
/*        addRound(){
          this.$showRefresher()
            .then(() => {
              var addedRound = RoundsConfigurationHelper.createRound(this.currentCompetition_id);
              this.selectedLevels.forEach(levelItem => {
                RoundsConfigurationHelper.createLevel(this.currentCompetition_id, addedRound.round_number, levelItem.code);
                this.selectedLevelsCategory(levelItem.code).forEach(categoryItem => {
                  RoundsConfigurationHelper.createCategory(this.currentCompetition_id, addedRound.round_number, levelItem.code, categoryItem.code);
                });
              });
              this.$hideRefresher();
            });
        },
        removeRound(){
          this.$showRefresher()
            .then(() => {
              RoundsConfigurationHelper.deleteRound(this.currentCompetition_id);
              this.$hideRefresher();
            });
        },*/
        addKeepableRounds(){
          this.newNbRoundToSkipOrKeep++;
        },
        removeKeepableRounds(){
          this.newNbRoundToSkipOrKeep--;
        },


    },
    async mounted(){
        this.checkSynchro();
        await this.refresh();
        this.$hideLoader();
    },
}
</script>
