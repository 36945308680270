<template>
    <div style="display:flex; justify-content:center; width:100%" v-if="d_show" >
        <div v-if="loading">PREPARATION DU DUMP EN COURS.<br/>
        Veuillez patienter quelques instants...</div>
        <div v-else>
      <collapse-panel class="level" :title="d.name" v-for="d in dumpData.levels" :key="d.code">
        <collapse-panel class="Round" :title="`Manche ${r.number} (${d.name})`" v-for="r in d.rounds" :key="`${d.code}-${r.number}`">
            <collapse-panel class="category" :title="`${c.name} (Manche ${r.number} - ${d.name})`" v-for="c in r.categories" :key="`${d.code}-${r.number}-${c.code}`">
                <collapse-panel class="event" :title="`${e.name} (${c.name} - Manche ${r.number} - ${d.name})`" v-for="e in c.events" :key="`${d.code}-${r.number}-${c.code}-${e.code}`">
                    <collapse-panel class="competitor" :title="`${comp.name}`" v-for="comp in e.competitors" :key="`${d.code}-${r.number}-${c.code}-${e.code}-${comp.id}`">
                        <table style="width:100%">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th style="width:10%" v-if="e.hasCompulsories">Fig.1</th>
                                    <th style="width:10%" v-if="e.hasCompulsories">Fig.2</th>
                                    <th style="width:10%" v-if="e.hasCompulsories">Fig.3</th>
                                    <th style="width:10%" v-if="e.hasRoutine">Exec.</th>
                                    <th style="width:10%" v-if="e.hasRoutine">Cont.</th>
                                    <th style="width:10%" v-if="e.hasBallet">Choreo.</th>
                                    <th style="width:10%" v-if="e.hasBallet">Exec.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="judge in comp.judges" :key="`${d.code}-${r.number}-${c.code}-${e.code}-${comp.id}-${judge.id}`">
                                    <td>{{ judge.name }}</td>
                                    <td v-if="e.hasCompulsories">{{ judge.fig1}}
                                        <b-badge @click="showPenalty(judge.fig1_penalties, `sur 1ère figure par ${judge.name}`)" v-if="judge.fig1_penalties != ''">P</b-badge>
                                    </td>
                                    <td v-if="e.hasCompulsories">{{ judge.fig2}}
                                        <b-badge @click="showPenalty(judge.fig2_penalties, `sur 2ème figure par ${judge.name}`)" v-if="judge.fig2_penalties != ''">P</b-badge>
                                    </td>
                                    <td v-if="e.hasCompulsories">{{ judge.fig3}}
                                        <b-badge @click="showPenalty(judge.fig3_penalties, `sur 3ème figure par ${judge.name}`)" v-if="judge.fig3_penalties != ''">P</b-badge>
                                    </td>
                                    <td v-if="e.hasRoutine">{{ judge.r_exec}}</td>
                                    <td v-if="e.hasRoutine">{{ judge.content}}
                                        <b-badge @click="showPenalty(judge.r_penalties, `sur routine par ${judge.name}`)" v-if="judge.r_penalties != ''">P</b-badge>
                                    </td>
                                    <td v-if="e.hasBallet">{{ judge.choreo}}</td>
                                    <td v-if="e.hasBallet">{{ judge.b_exec}}
                                        <b-badge @click="showPenalty(judge.b_penalties, `sur ballet par ${judge.name}`)" v-if="judge.b_penalties != ''">P</b-badge>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </collapse-panel>
                </collapse-panel>
            </collapse-panel>
        </collapse-panel>
      </collapse-panel> 
    </div>
    <b-modal
              :title="`Pénalités ${d_penalties_title}`"
              v-model = "d_showPenalty"
              size = "sm"
              ok-only
            >
                <table>
                    <tr v-for="penalty in d_penalties" :key="penalty.key">
                        <td style="min-width:75px;">{{  penalty.key }}</td>
                        <td style="width:20px;"> : </td>
                        <td>{{  penalty.value }}</td>
                    </tr>
                </table>
            </b-modal>
    </div>
</template>

<script>
import CollapsePanel from '../../../components/Panels/CollapsePanel.vue';
import { RST_EVENT, RST_COMPETITOR, RST_COMPULSORY_NOTE, RST_ROUTINE_NOTE, RST_BALLET_NOTE, REF_CATEGORY, RST_COMPETITION_STAFF, RST_ROUND, RST_LEVEL } from '../../../LocalDB';

export default {
    components: { CollapsePanel },
    props: {
        competition_id: { type: String, required: true},
        show: { type: Boolean, required: true}
    },
    data(){
        return {
            d_show: this.show,
            d_competition_id: this.competition_id,
            dumpData: {
                competition_id: this.competition_id,
                levels: []
            },
            loading: false,
            d_penalties: [],
            d_showPenalty:false,
            d_penalties_title: '',
        };
    },
    methods:{
        showPenalty(p, title){
            var o = JSON.parse(p);
            var kv = [];
            for(const [key,value] of Object.entries(o)){
                kv.push({ key: key, value: value});
            }
            this.d_penalties_title = title;
            this.d_penalties = kv;
            this.d_showPenalty=true;
        },
        sortNotes(n){
            return n.JUDGE.name
        },
        refresh(){
             var ret = {
                competition_id: this.d_competition_id,
                levels: [],
            };
            var dbCompulsories = RST_COMPULSORY_NOTE.query()
                .where('competition_id', this.d_competition_id)
                .where(n => n.judge_id != null)
                .where(n => n.compulsory_number != null)
                //.orderBy(j => this.sortNotes(j))
                .get();
            var dbRoutines = RST_ROUTINE_NOTE.query()
                .where('competition_id', this.d_competition_id)
                .where(n => n.judge_id != null)
                //.orderBy(j => this.sortNotes(j))
                .get();
            var dbBallets = RST_BALLET_NOTE.query()
                .where('competition_id', this.d_competition_id)
                .where(n => n.judge_id != null)
                //.orderBy(j => this.sortNotes(j))
                .get();
            var dbCompetitors = RST_COMPETITOR.query()
                .where('competition_id', this.d_competition_id)
                .get();
            var dbEvents = RST_EVENT.query()
                .where('competition_id', this.d_competition_id)
                .get();
            var dbCategories = REF_CATEGORY.query()
                //.where('competition_id', this.d_competition_id)
                .get();
            var dbRounds = RST_ROUND.query()
                .where('competition_id', this.d_competition_id)
                .get();
            var dbLevels = RST_LEVEL.query()
                .where('competition_id', this.d_competition_id)
                .get();
            var dbStaff = RST_COMPETITION_STAFF.query()
                .where('competition_id', this.d_competition_id)
                //.where('isJudge', true)
                .get();

            dbCompulsories.forEach(n => {
                var level = ret.levels.filter(l => l.code == n.level)[0];
                if(level == undefined){
                    level = dbLevels.filter(l => l.level == n.level).map(l => { return {code: l.level, name: l.LEVEL.name, rounds: []}})[0];
                    ret.levels.push(level);
                }
                var round = level.rounds.filter(r => r.number == n.round_number)[0];
                if(round == undefined){
                    round = dbRounds.filter(r => r.round_number == n.round_number).map(r => {return {number: r.round_number, categories: []}})[0];
                    level.rounds.push(round);
                }
                var category = round.categories.filter(c => c.code == n.category)[0];
                if(category == undefined){
                    category = dbCategories.filter(c => c.code == n.category).map(c => { return {code: c.code, name: c.name, events: []}})[0];
                    round.categories.push(category);
                }
                var event = category.events.filter(e => e.code == n.event)[0];
                if(event == undefined){
                    event = dbEvents.filter(e => e.event == n.event).map(e => { return {code: e.event, name: e.REF_EVENT.name, hasCompulsories: e.REF_EVENT.hasCompulsories, hasRoutine: e.REF_EVENT.hasRoutine, hasBallet: e.REF_EVENT.hasBallet, competitors: [] }})[0];
                    category.events.push(event);
                }
                var competitor = event.competitors.filter(c => c.id == n.competitor_id)[0];
                if(competitor == undefined){
                    competitor = dbCompetitors.filter(c => c.competitor_id == n.competitor_id)
                        .map(c => { return {id: c.competitor_id, name: c.COMPETITOR.name, judges: []}})[0];
                        event.competitors.push(competitor);
                }
                var judge = competitor.judges.filter(j => j.id == n.judge_id)[0];
                if(judge == undefined){
                    judge = dbStaff.filter(j => j.staff_id == n.judge_id).map(j => { return { id: j.staff_id, name: j.name}})[0];
                    competitor.judges.push(judge);
                }
                judge[`fig${n.compulsory_number}`] = n.noteEntry;
                judge[`fig${n.compulsory_number}_penalties`] = n.penalty > 0 ? n.penalties : "";
            })

            dbRoutines.forEach(n => {
                var level = ret.levels.filter(l => l.code == n.level)[0];
                if(level == undefined){
                    level = dbLevels.filter(l => l.level == n.level).map(l => { return {code: l.level, name: l.LEVEL.name, rounds: []}})[0];
                    ret.levels.push(level);
                }
                var round = level.rounds.filter(r => r.number == n.round_number)[0];
                if(round == undefined){
                    round = dbRounds.filter(r => r.round_number == n.round_number).map(r => {return {number: r.round_number, categories: []}})[0];
                    level.rounds.push(round);
                }
                var category = round.categories.filter(c => c.code == n.category)[0];
                if(category == undefined){
                    category = dbCategories.filter(c => c.code == n.category).map(c => { return {code: c.code, name: c.name, events: []}})[0];
                    round.categories.push(category);
                }
                var event = category.events.filter(e => e.code == n.event)[0];
                if(event == undefined){
                    event = dbEvents.filter(e => e.event == n.event).map(e => { return {code: e.event, name: e.REF_EVENT.name, hasCompulsories: e.REF_EVENT.hasCompulsories, hasRoutine: e.REF_EVENT.hasRoutine, hasBallet: e.REF_EVENT.hasBallet, competitors: [] }})[0];
                    category.events.push(event);
                }
                var competitor = event.competitors.filter(c => c.id == n.competitor_id)[0];
                if(competitor == undefined){
                    competitor = dbCompetitors.filter(c => c.competitor_id == n.competitor_id)
                        .map(c => { return {id: c.competitor_id, name: c.COMPETITOR.name, judges: []}})[0];
                        event.competitors.push(competitor);
                }
                var judge = competitor.judges.filter(j => j.id == n.judge_id)[0];
                if(judge == undefined){
                    judge = dbStaff.filter(j => j.staff_id == n.judge_id).map(j => { return { id: j.staff_id, name: j.name}})[0];
                    competitor.judges.push(judge);
                }
                judge.r_exec = n.execution;
                judge.content = n.content
                judge.r_penalties = n.penalty > 0 ? n.penalties : "";
            })
            
            dbBallets.forEach(n => {
                var level = ret.levels.filter(l => l.code == n.level)[0];
                if(level == undefined){
                    level = dbLevels.filter(l => l.level == n.level).map(l => { return {code: l.level, name: l.LEVEL.name, rounds: []}})[0];
                    ret.levels.push(level);
                }
                var round = level.rounds.filter(r => r.number == n.round_number)[0];
                if(round == undefined){
                    round = dbRounds.filter(r => r.round_number == n.round_number).map(r => {return {number: r.round_number, categories: []}})[0];
                    level.rounds.push(round);
                }
                var category = round.categories.filter(c => c.code == n.category)[0];
                if(category == undefined){
                    category = dbCategories.filter(c => c.code == n.category).map(c => { return {code: c.code, name: c.name, events: []}})[0];
                    round.categories.push(category);
                }
                var event = category.events.filter(e => e.code == n.event)[0];
                if(event == undefined){
                    event = dbEvents.filter(e => e.event == n.event).map(e => { return {code: e.event, name: e.REF_EVENT.name, hasCompulsories: e.REF_EVENT.hasCompulsories, hasRoutine: e.REF_EVENT.hasRoutine, hasBallet: e.REF_EVENT.hasBallet, competitors: [] }})[0];
                    category.events.push(event);
                }
                var competitor = event.competitors.filter(c => c.id == n.competitor_id)[0];
                if(competitor == undefined){
                    competitor = dbCompetitors.filter(c => c.competitor_id == n.competitor_id)
                        .map(c => { return {id: c.competitor_id, name: c.COMPETITOR.name, judges: []}})[0];
                        event.competitors.push(competitor);
                }
                var judge = competitor.judges.filter(j => j.id == n.judge_id)[0];
                if(judge == undefined){
                    judge = dbStaff.filter(j => j.staff_id == n.judge_id).map(j => { return { id: j.staff_id, name: j.name}})[0];
                    competitor.judges.push(judge);
                }
                judge.choreo = n.choreo
                judge.b_exec = n.execution;
                judge.b_penalties = n.penalty > 0 ? n.penalties : "";
            })
            this.dumpData = ret;
            this.loading = false;
        },
    },
    async mounted(){
    },
    watch: {
        show (newValue) {
            if(newValue != this.d_show){
                this.d_show = newValue;
                if(newValue)
                    this.loading=true;
                    setTimeout(this.refresh, 500);
            }
        },
        competition_id (newValue){
            if(newValue != this.d_competition_id){
                this.d_competition_id = newValue;
                this.loading = true;
                setTimeout(this.refresh, 500);
            }
        }
    }
}
</script>